

























































































































































































































































































































































.chat-dragger .el-upload-dragger {
  height: 80px;
  width: 200px;
  padding: 5px 10px;
  border-radius: unset;
}

.comments-block .el-upload-dragger .el-icon-upload {
  font-size: 26px;
  margin: 0;
  line-height: unset;
}

.buttonBLock {
	display: flex;
}
