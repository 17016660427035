


































































































































































































































































































@import "./src/assets/css/project-variables";


.card-stage-wrapper {
  //border: 1px dashed $--color-primary;
  //border-radius: $--border-radius-base;
  //padding: 10px 20px;
  //
  //&.active {
  //  border: 3px solid $--color-primary;
  //}
}

.card-stage-header {
  text-align: center;
  padding-bottom: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;

  .card-stage-header-name {
    font-weight: bold;
  }
}

.card-stage-divider {
  margin:5px;
}




.timeline {
  list-style-type: none;

  padding:0;
  margin: 0;
  display: flex;
  overflow: auto;
  justify-content: flex-start;
  align-items: flex-start;

  &.oneNegotiation{
    justify-content: center;
  }


  .negotiation {
    padding: 25px 0 10px 0;
    min-width: 150px;
    position: relative;
    &:hover{
      border-radius: $--border-radius-base;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

      .negotiation-bottom {
        border-top: 2px solid #ffffff !important;

        &-icon, &:before {
          display: none !important;
        }
      }

      .negotiation-actions{
        display: flex;
        justify-content: space-around;
      }
    }

    //&:first-child .negotiation-bottom:before{
    //  left:5%;
    //}
    //&:last-child .negotiation-bottom:before{
    //  left:unset;
    //  right:5%;
    //}

    &.complete {
      .negotiation-bottom {
        border-top: 2px solid $--color-success;
      }
      .negotiation-bottom:before {
        background-color: $--color-success;
      }

      .negotiation-bottom-icon {
        color: white;
      }
    }
    &.active {
      .negotiation-bottom:before {
        background-color: $--color-primary;
      }
      .negotiation-bottom-icon {
        color: white;
      }
    }

    &-top {
      margin-bottom: 20px;
      padding: 0px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 100;
    }

    &-bottom{
      padding: 20px 5px 0 5px;
      text-align: center;
      position: relative;
      border-top: 2px solid #adadad;

      &-type{
        position: relative;
        z-index: 1;
        font-weight: bold;
      }

      &-icon {
        position: absolute;
        top: -13px;
        left: calc(50% - 9px);
        font-size: 20px;
        color: #eee;
      }

      &:before {
        content: '';
        width: 53px;
        height: 53px;
        background-color: white;
        position: absolute;
        top: -28px;
        left: calc(50% - 25px);
        transition: all 200ms ease-in;
        border: 10px solid #fff;
        border-radius: 50px;
        box-sizing: border-box;
      }

    }

    &-edit-button{
      font-size: 1.1rem;
      margin-top: -5px;
    }
    &-delete-button{
      color: $--color-danger;
      font-size: 1.1rem;
      margin-top: -5px;
    }

    .negotiation-actions {
      position: absolute;
      top:15px;
      z-index: 10;
      width: 100%;
      display: none;
      justify-content: space-around;
      font-size: 1.1rem;

      &-drag-handle{
        cursor: move;
      }
    }
  }
}





