







































































@import "@/assets/css/project-variables";

.pagination {
  position: relative;
  margin: 32px 0 0;

  &__amount {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    transform: translateY(-50%);

    .el-button.el-button--default {
      padding: 0;
      margin: 0 16px 0 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      border: none;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: $--color-primary;
        font-weight: bold;
      }

      &:hover, &:focus {
        background-color: transparent;
        color: $--color-primary;
      }
    }
  }
}

