

























































@import "./src/assets/css/project-variables";

.open-desc-button {
  color: gray;

  &.has-desc{
    color: $--color-primary;
  }
}

