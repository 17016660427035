















































































































.import-modal-upload {
  width: 100%;

  & .el-upload {
    width: 100%;
    //height: 100%;

    & .el-upload-dragger {
      padding: 5px;
      width: 100%;
    }
  }


  &.tight {
    & .el-upload-dragger {
      height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .el-upload__text-block{
      display: inline-block;
      margin-left: 10px;
    }
    & .el-icon-upload {
      margin: unset;
      font-size: 45px;
      line-height: unset;
    }
  }


}
