



















































































































































































@import "./src/assets/css/project-variables";

.card-stages {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1.3rem;
  justify-content: space-around;

  .stage{
    min-width: 360px;
  }
}

.stage.el-card.is-always-shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%)
}
